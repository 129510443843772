<script>
import IconPending from '../../assets/icon-pending'
import IconWaiting from '../../assets/icon-waiting-wallet.vue'
import IconRevoked from '../../assets/icon-revoked-table.vue'
import IconApproved from '../../assets/icon-approved'
import ViewCredCardModal from '../../modals/ViewCredentialCardModal'
import ViewCancelCertModal from '../../modals/ViewCancelCertModal'
import RevokeCertModal from '../../modals/RevokeCertModal'

import IconMenu from '../../assets/icon-dot-menu'

export default {
  name: 'DataTable',
  components: {
    IconPending,
    IconApproved,
    IconRevoked,
    IconWaiting,
    ViewCredCardModal,
    ViewCancelCertModal,
    IconMenu,
    RevokeCertModal,
  },
  props: {
    search: {},
    headers: {
      required: true,
    },
    items: {
      required: true,
    },
    tableOptions: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update-table'],
  data() {
    return {
      userData: {},
      showViewCredentialCardModal: false,
      showViewCancelCertModal: false,
      showRevokeCertModal: false,
      snackbar: false,
      frontendProps: null,
      templateItens: null,
    }
  },
  created() {},
  methods: {
    getTemplate(tid) {
      const template = this.$store.state.templates

      return template.find(t => t.tid === tid)
    },
    view(data) {
      // this.$emit("view", key);
      console.log(data)
      console.log(this.userData)
      const template = this.getTemplate(data.tid)
      this.userData = data
      this.userData.certName = template.name
      this.frontendProps = template.frontend_props
      this.templateItens = template.templateItens
      console.log(this.userData.certName)
      // for (var a in data.user_data) {
      //   var val = data.user_data[a];
      //   arr.push({ attr: a, value: val });
      // }

      // this.userData.id = data.id;
      // this.userData.tid = data.tid;
      this.showViewCredentialCardModal = true
    },
    download(item) {
      console.log('download credential', item)
      let PDF_URL

      if (item.pdf_url) {
        window.open(item.pdf_url)
      }
      else {
        if (process.env.VUE_APP_AMBIENT === 'DEMO') {
          PDF_URL = 'https://demo.mycredentials.wallid.io/ViewCredential/'
        }
        else {
          PDF_URL = 'https://mycredentials.wallid.io/ViewCredential/'
        }
        window.open(PDF_URL + item.id)
      }
    },
    revoke(data) {
      this.userData.id = data.id
      this.userData.tid = data.tid
      this.showRevokeCertModal = true
    },
    cancel(data) {
      this.userData.id = data.id
      this.userData.tid = data.tid
      this.showViewCancelCertModal = true
    },
    back() {
      this.showRevokeCertModal = false
      this.showViewCancelCertModal = false
      if (this.userData.user_data) {
        this.showViewCredentialCardModal = true
      }
    },
    updateTable() {
      console.log('DataTable:updateTable')
      this.userData = {}
      this.$emit('update-table')
    },
    approveAll(item) {
      console.log(item)

      for (let index = 0; index < this.items.length; index++) {
        this.approve(this.items[index])
      }
    },
  },
}
</script>

<template>
  <div>
    <v-data-table
      class="dca-table"
      :search="search"
      :headers="headers[$i18n.locale]"
      :items="items"
      :no-results-text="$t('table.noData')"
      :items-per-page="10"
      :hide-default-footer="items.length < 10"
      :hide-default-header="items.length === 0"
      :loading="loading"
    >
      <template #no-data>
        <slot name="empty-table" />
      </template>

      <template #[`header.actions`]="{ item }">
        <v-menu bottom left :nudge-width="200" offset-y>
          <template #activator="{ on }">
            <v-btn
              v-show="tableOptions"
              :ripple="false"
              class="dot-menu"
              icon
              v-on="on"
            >
              <IconMenu />
            </v-btn>
          </template>

          <slot name="actions">
            <v-list :ripple="false" class="dot-menu">
              <v-list-item :ripple="false" @click="approveAll(item)">
                <v-list-item-title class="menu-title">
                  {{
                    $t('table.menu.all')
                  }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </slot>
        </v-menu>
      </template>
      <template #[`item.public_field`]="{ item }">
        {{ item.public_field.value }} ({{ item.public_field.attr }})
      </template>
      <template #[`item.status`]="{ item }">
        <div>
          <IconPending v-if="item.status === PENDING_APPROVAL" />
          <IconWaiting v-if="item.status === WAITING_WALLET" />
          <IconApproved v-if="item.status === APPROVED" />
          <IconRevoked v-if="item.status === REVOKED" />

          {{ $t(`status.${item.status}`) }}
        </div>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-menu
          bottom
          left
          :nudge-width="200"
          offset-y
        >
          <template #activator="{ on }">
            <v-btn :ripple="false" class="dot-menu" icon v-on="on">
              <IconMenu />
            </v-btn>
          </template>

          <slot name="actions">
            <v-list :ripple="false" class="dot-menu">
              <v-list-item :ripple="false" @click.stop @click="view(item)">
                <v-list-item-title class="menu-title">
                  {{
                    $t('table.menu.view')
                  }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  false
                "
                :ripple="false"
                @click="download(item)"
              >
                <v-list-item-title class="menu-title">
                  {{
                    $t('table.menu.download')
                  }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.status === PENDING_APPROVAL"
                :ripple="false"
                @click="approve(item)"
              >
                <v-list-item-title class="menu-title">
                  {{
                    $t('table.menu.approve')
                  }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.status === APPROVED"
                :ripple="false"
                @click="revoke(item)"
              >
                <v-list-item-title class="menu-title">
                  {{
                    $t('table.menu.revoke')
                  }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  item.status === PENDING_APPROVAL
                    || item.status === WAITING_WALLET
                "
                :ripple="false"
                @click="cancel(item)"
              >
                <v-list-item-title class="menu-title">
                  {{
                    $t('table.menu.cancel')
                  }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </slot>
        </v-menu>
      </template>
    </v-data-table>

    <ViewCredCardModal
      v-if="showViewCredentialCardModal"
      :frontend-props="frontendProps"
      :user-data="userData"
      :template-itens="templateItens"
      @close="() => {
        showViewCredentialCardModal = false;
        updateTable()
      }
      "
      @cancel="
        (showViewCancelCertModal = true), (showViewCredentialCardModal = false)
      "
    />

    <RevokeCertModal
      v-if="showRevokeCertModal"
      :user-data="userData"
      @close="() => {
        showRevokeCertModal = false;
        updateTable()
      }
      "
      @back="back"
      @cancel="cancel"
    />
    <ViewCancelCertModal
      v-if="showViewCancelCertModal"
      :user-data="userData"
      @close=" () => {
        showViewCancelCertModal = false;
        updateTable()
      }
      "
      @back="back"
      @cancel="cancel"
    />
  </div>
</template>

<style lang="scss">
.v-data-table.dca-table .v-data-table__wrapper {
  box-shadow: 0 2px 4px 0 var(--very-light-grey) !important;

  & table > tbody > tr:hover {
    background-color: var(--white) !important;
  }
  & table thead > tr > th {
    padding: 0 16px;
  }
  & table tbody > tr > td,
  & table thead > tr > th,
  & table tfoot > tr > td {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
  }
   div.v-progress-linear__indeterminate.v-progress-linear__indeterminate--active > div.v-progress-linear__indeterminate.long.primary,
   div.v-progress-linear__indeterminate.v-progress-linear__indeterminate--active > div.v-progress-linear__indeterminate.short.primary {
    background-color: var(--teal-blue) !important;
   }
  & tr > td {
    svg {
      margin-bottom: -5px;
      margin-right: 4px;
    }
  }
  & table thead > tr > th:last-child,
  & table tbody > tr:not(.v-data-table__empty-wrapper) > td:last-child {
    text-align: end !important;
    padding-left: 0;
  }
  & table tbody > tr.v-data-table__empty-wrapper > td:first-child {
    padding: 0px;
  }
  & table thead > tr > th:last-child {
    padding-right: 4px;
  }
  & table tbody > tr > td:last-child {
    padding: 0 4px;
  }
  & table thead tr > th {
    font-weight: 600;
  }

  th {
    padding: 16px;
  }
}
</style>
